import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroArea from '../components/hero-area'
import CalloutBar from '../components/callout-bar'
import Card from '../components/card'
import styled from 'styled-components'
import FeaturedItems from '../components/featured-items'
import PhotoGalleryComponent from '../components/photo-gallery-component'

import CTA from '../components/cta-bar'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'
import parse, { attributesToProps } from 'html-react-parser'
import { TestimonialSection } from '../components/testimonial_video'
import { useTextReplacement } from '../hooks/useTextReplacement'
import { StaticImage } from 'gatsby-plugin-image'

import { Popover } from 'react-tiny-popover'

const MarginTop = styled.div`
  margin-top: -29px;
`
const Intro = styled.div`
  text-align: center;
  padding-top: 30px;
  text-transform: uppercase;
`
const IntroBar = styled.div`
  margin-bottom: 32px;
  padding-bottom: 11px;
`
const GalleryHomePage = styled.div`
  padding: 2.5em 0;
  background: white;
  margin-bottom: 2em;
  text-align: center;
  h2 {
    margin-bottom: 0.35em;
    font-size: calc(0.75vw + 1.2em);
  }
  p {
    max-width: 767px;
    color: #555;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }
  img:nth-child(15) {
    display: none;
  }
`

function isInViewPort(el, topOffSet = 300) {
  var top = el.offsetTop
  var left = el.offsetLeft
  var width = el.offsetWidth
  var height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop - topOffSet
    left += el.offsetLeft
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  )
}

const IndexPage = (props) => {
  const { productImage = { edges: [] } } = props.data
  const homePageNode = props.data.allContentfulPage.edges[0].node

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Layout
      isHomePage={true}
      pageProps={props}
      footerLogoShowcase={'full-deck-plan-set'}
    >
      <SEO
        title="Full Deck Plan Set - Affordable Deck Plans"
        description={
          'Full Set of 11x17 Deck Plans in PDF format Professionally drafted AutoCAD deck plans based on your hand sketch and project information. Includes: 2018 International Residential Building Code Compliant'
        }
        keywords={['gatsby', 'application', 'react']}
      />
      <div className="flex flex-col md:flex-row md:gap-x-8 container mx-auto mt-8 space-y-8 md:space-y-0 px-3 md:px-0">
        <div className="w-full md:w-3/5">
          <StaticImage
            placeholder="blurred"
            className="lg:col-span-2 lg:row-span-2 rounded-lg shadow-md"
            src={'../images/deck-plans.png'}
            width={725}
            layout={'constrained'}
          />
          <div className="grid grid-cols-4 gap-4 mt-4">
            <StaticImage
              placeholder="blurred"
              className="col-span-2 rounded-lg shadow-md"
              src={`../images/full-deck-plans/n-highland-place-1.jpg`}
              width={360}
              aspectRatio={1.77}
            />
            <StaticImage
              placeholder="blurred"
              className="col-span-2 rounded-lg shadow-md"
              src={`../images/full-deck-plans/n-highland-place-2.jpg`}
              width={360}
              aspectRatio={1.77}
            />
            <StaticImage
              placeholder="blurred"
              className="col-span-2 rounded-lg shadow-md"
              src={`../images/full-deck-plans/n-highland-place-3.jpg`}
              width={360}
              aspectRatio={1.77}
            />
            <StaticImage
              placeholder="blurred"
              className="col-span-2 rounded-lg shadow-md"
              src={`../images/full-deck-plans/n-highland-place-4.jpg`}
              width={360}
              aspectRatio={1.77}
            />
          </div>
        </div>
        <div className="w-full md:w-2/5 space-y-8">
          <div>
            <h2>Custom Professional Deck Plans</h2>

            <div class="flex justify-between">
              <h1 class="text-xl font-large text-gray-900 mb-0">
                Full Deck Plan Set
              </h1>
              <p class="text-xl font-medium text-gray-900 mb-0">$450</p>
            </div>

            <div class="mt-2"></div>
          </div>
          <div class="mt-8 flex sm:flex-row md:flex-col lg:flex-row lg:mt-0 lg:flex-shrink-0 gap-3">
              <a
                href="https://buy.stripe.com/fZedT24HLaBw9HibIK"
                target="_blank"
                class="rounded-md shadow items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-full text-white button-style-primary"
              >
                Buy Now
              </a>
            <Popover
              isOpen={isPopoverOpen}
              positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
              content={
                <div className="flex flex-col bg-white rounded-xl shadow-md p-2">
                  <a
                    className="p-3"
                    href="/assets/full-deck-plans/USQC-Deck-Design-SAMPLE.pdf"
                    target="_blank"
                  >
                    Sample 1
                  </a>
                  <a
                    className="p-3"
                    href="/assets/full-deck-plans/USQC-Deck-Design.pdf"
                    target="_blank"
                  >
                    Sample 2
                  </a>
                </div>
              }
            >
              <div
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                class="items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-full button-secondary-deck cursor-pointer rounded-md shadow"
              >
                PDF Samples
              </div>
            </Popover>
          </div>
          <div class="mt-10">
            <h2 class="text-sm font-medium text-gray-900">Description</h2>

            <div class="mt-2 prose prose-sm text-gray-500">
              <p>
                Visualize your deck in 3D! Order your 2018 International
                Residential Code compliant deck plans. Decided to hire us? Ask
                your Deck Consultant for a full refund upon signing with our
                company.
              </p>
              <p>
                Full Set of 11x17 Deck Plans in PDF format. Professionally
                drafted AutoCAD deck plans based on your hand sketch and project
                information.
              </p>
            </div>
          </div>
          <div class="mt-0 border-t border-gray-200 pt-2">
            <h2 class="text-sm font-medium text-gray-900">Includes</h2>

            <div class="mt-4 prose prose-sm text-gray-500">
              <ul role="list">
                <li>2018 International Residential Building Code Compliant</li>
                <li>Jobsite Address</li>
                <li>Framing Plan</li>
                <li>Footing Layout</li>
                <li>Birds-Eye Elevation</li>
                <li>Front Elevation</li>
                <li>Side Elevation</li>
              </ul>
              <p className="mt-2">3D Rendering Available Upon Request.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          featuredItems {
            id
            serviceTitle
            featuredLabel
            excerpt
            shortExcerpt
            slug
            heroImage {
              gatsbyImageData(width: 370)
            }
          }

          id
          slug
          pageTitle
          metaTitle
          metaRobotsContent
          metaDescription
          showReputationawardsSection
          featuredContent {
            featuredContent
          }
          html {
            html
          }
          body {
            body
          }
          footerLogoShowcase
          calloutBar {
            id
            title
            description
            backgroundColor
            textColor
          }
          footerCallToActionHeading
          footerCallToActionDesc
          footerCallToActionImg {
            gatsbyImageData(width: 1800)
          }
          photoGallery {
            id
            title
            description {
              description
            }
            photos {
              id
              title
              fixed(width: 300, height: 250) {
                width
                height
                src
                srcSet
              }
              fluid(maxWidth: 1600) {
                src
              }
            }
          }
          heroArea {
            heroTitle
            description {
              description
            }
            primaryActionBtnLabel
            primaryActionBtnLink
            heroImage {
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
          }
          heroAreaCarousel {
            heroTitle
            description {
              description
            }
            primaryActionBtnLabel
            primaryActionBtnLink
            heroImage {
              gatsbyImageData(width: 1400, quality: 70, placeholder: BLURRED)
            }
          }
          testimonialSection {
            testimonials {
              id
              title
              projectReference {
                slug
              }
              video {
                id
                title
                file {
                  url
                  contentType
                }
              }
              thumbnail {
                gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
              }
            }
          }
          sidebar {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
    }
  }
`
